@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,300;1,700&display=swap");
@import "../src/variables.scss";

* {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

p {
  color: $primary-dark;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

.sub-title {
  font-style: italic;
  margin-top: 0.6em;
  margin-bottom: 0;
  font-size: 2em;
}

#pre-loader,
#loaded {
  text-align: center;
}

#pre-loader {
  margin-top: 20vh;
}

svg.MuiSvgIcon-root {
  height: 1.5em;
  width: 1.5em;
}

path {
  color: #fff;
}

#profilePicture {
  width: 16em;
}

#top-banner {
  background: #18a2b8;
  padding: 1em;
}

#app-container {
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 100vh;
}

#spinner {
  width: 5em;
  height: 5em;
}

#menu {
  text-align: center;
  a {
    display: block;
    color: white;
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  a:hover {
    text-decoration: none;
    font-weight: 700;
  }
  .selected-menu-item {
    font-size: 2.5em;
    font-weight: 700;
    background-color: #fff;
    color: rgb(133, 133, 133);
  }
  .selected-menu-item:hover {
    text-decoration: none;
  }
}

#app-container h1 {
  font-weight: 700;
}

.skill-logo {
  text-align: center;
  img {
    width: 3em;
    height: 3em;
    margin-bottom: 1em;
  }
}

#business-card {
  margin-bottom: 2em;
}

ul {
  li {
    margin-top: 0.6em;
  }
}

#external-links-wrapper {
  margin: 2em 0;

  .external-links-button {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .external-links-button:last-child {
    margin-right: 0;
  }
}

#Contact-Page--Container {
  height: 110vh;

  a:hover {
    text-decoration: none;
  }

  button {
    margin-bottom: 1em;
  }
}

#dark-mode-toggle {
  path {
      color: $primary-gray;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #top-banner {
    h1 {
      text-align: center;
    }
  }

  .skill-logo {
    // This is our sub title on the main card
    div {
      font-size: 0.8em;
    }
  }
}

// TODO: I know this isn't ideal but Material-UI has some quirks with custom theming the
// custom styles on :hoverand :active buttons so I'll have to come back to this later
a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.external-links-button.MuiButton-containedPrimary.MuiButton-fullWidth {
  color: #fff;
}

.btn-resume-download a {
  color: #fff;
  padding: 0.3em;
}

.btn-resume-download a:hover {
  text-decoration: none;
  color: #fff;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  color: #fff;
}

#Contact-Page--Image {
  border: 1px solid $light-gray;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  max-width: 100%;
  margin-bottom: 2em;
}