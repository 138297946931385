@import '../variables.scss';

#Menu-Component--Container{
    margin-bottom: 2em;

    #Menu-Component-SubNav {
        margin: 0;
        text-align: center;
    }

    .selected-menu-item {
        button.btn.btn-secondary {
            border-bottom: 0.5em solid $primary-blue;
            color: $primary-blue;
            font-weight: 700;
            box-shadow: none;
            transition: width .3s, height .3s, transform .3s;
            transition-timing-function: ease;
            border-radius: 0;

            svg.MuiSvgIcon-root {
                fill: $primary-dark;
            }
        }
    }

    button.btn.btn-secondary {
        transition: width .3s, height .3s, transform .3s;
        transition-timing-function: ease;
        color: $primary-gray;
        border: none;
        background: #fff;

        svg.MuiSvgIcon-root {
            fill: $primary-gray;
            height: 1em;
            width: 1em;
        }
    }

    button.btn.btn-secondary {
        font-size: 1.3em;
    }

}
